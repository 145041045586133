<template>
    <div class="white fixed z-50 h-full w-full bg-white">
        <div class="translate-x-50 left-1/2.5 relative top-1/2">
            <div class="m-100 spinner-annimation m-auto h-20 w-20 rounded-full bg-brand-400" />
        </div>
    </div>
</template>

<style scoped>
.spinner-annimation{
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
</style>

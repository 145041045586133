<template>
    <div>
        <VitePwaManifest />
        <LoadSpinner v-if="displayOnLoad" />
        <NuxtLayout>
            <LoadSpinner
                v-if="showSpinner"
                class="fixed left-0 top-0 h-full w-full items-center justify-center"
            />
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">
const showSpinner = ref(false);
const displayOnLoad = ref(true);
const nuxtApp = useNuxtApp();

nuxtApp.hook('page:start', () => {
    showSpinner.value = true;
});
nuxtApp.hook('page:finish', () => {
    showSpinner.value = !showSpinner.value;
});

onMounted(() => {
    displayOnLoad.value = false;
    registerServiceWorker();
});

async function registerServiceWorker() {
    if ('serviceWorker' in navigator)
        await navigator.serviceWorker
            .register('/worker.js');
}
</script>

import { default as permissions2P43Zi1u8kMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/permissions.vue?macro=true";
import { default as roleswMqiskakJmMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/roles.vue?macro=true";
import { default as rolesXRTkiK3SeYMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/user/roles.vue?macro=true";
import { default as userinvitesR9qHNqiwSMMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/userinvites.vue?macro=true";
import { default as usersAmXsWkx6a1Meta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/users.vue?macro=true";
import { default as callbackO1qI0txsdjMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/callback.vue?macro=true";
import { default as custom_45fields_91_91id_93_93Od2OtTqeElMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/custom-fields[[id]].vue?macro=true";
import { default as index7VedjHt42GMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/index.vue?macro=true";
import { default as issue_45form_91_91id_93_93q9mrZpWTlRMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue-form[[id]].vue?macro=true";
import { default as details_45_91id_938zaJL4RdL0Meta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue/details-[id].vue?macro=true";
import { default as issuesa2wSH6FP6vMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issues.vue?macro=true";
export default [
  {
    name: permissions2P43Zi1u8kMeta?.name ?? "admin-permissions",
    path: permissions2P43Zi1u8kMeta?.path ?? "/admin/permissions",
    meta: permissions2P43Zi1u8kMeta || {},
    alias: permissions2P43Zi1u8kMeta?.alias || [],
    redirect: permissions2P43Zi1u8kMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/permissions.vue").then(m => m.default || m)
  },
  {
    name: roleswMqiskakJmMeta?.name ?? "admin-roles",
    path: roleswMqiskakJmMeta?.path ?? "/admin/roles",
    meta: roleswMqiskakJmMeta || {},
    alias: roleswMqiskakJmMeta?.alias || [],
    redirect: roleswMqiskakJmMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/roles.vue").then(m => m.default || m)
  },
  {
    name: rolesXRTkiK3SeYMeta?.name ?? "admin-user-roles",
    path: rolesXRTkiK3SeYMeta?.path ?? "/admin/user/roles",
    meta: rolesXRTkiK3SeYMeta || {},
    alias: rolesXRTkiK3SeYMeta?.alias || [],
    redirect: rolesXRTkiK3SeYMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/user/roles.vue").then(m => m.default || m)
  },
  {
    name: userinvitesR9qHNqiwSMMeta?.name ?? "admin-userinvites",
    path: userinvitesR9qHNqiwSMMeta?.path ?? "/admin/userinvites",
    meta: userinvitesR9qHNqiwSMMeta || {},
    alias: userinvitesR9qHNqiwSMMeta?.alias || [],
    redirect: userinvitesR9qHNqiwSMMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/userinvites.vue").then(m => m.default || m)
  },
  {
    name: usersAmXsWkx6a1Meta?.name ?? "admin-users",
    path: usersAmXsWkx6a1Meta?.path ?? "/admin/users",
    meta: usersAmXsWkx6a1Meta || {},
    alias: usersAmXsWkx6a1Meta?.alias || [],
    redirect: usersAmXsWkx6a1Meta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: callbackO1qI0txsdjMeta?.name ?? "callback",
    path: callbackO1qI0txsdjMeta?.path ?? "/callback",
    meta: callbackO1qI0txsdjMeta || {},
    alias: callbackO1qI0txsdjMeta?.alias || [],
    redirect: callbackO1qI0txsdjMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: custom_45fields_91_91id_93_93Od2OtTqeElMeta?.name ?? "custom-fieldsid",
    path: custom_45fields_91_91id_93_93Od2OtTqeElMeta?.path ?? "/custom-fields:id?",
    meta: custom_45fields_91_91id_93_93Od2OtTqeElMeta || {},
    alias: custom_45fields_91_91id_93_93Od2OtTqeElMeta?.alias || [],
    redirect: custom_45fields_91_91id_93_93Od2OtTqeElMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/custom-fields[[id]].vue").then(m => m.default || m)
  },
  {
    name: index7VedjHt42GMeta?.name ?? "index",
    path: index7VedjHt42GMeta?.path ?? "/",
    meta: index7VedjHt42GMeta || {},
    alias: index7VedjHt42GMeta?.alias || [],
    redirect: index7VedjHt42GMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: issue_45form_91_91id_93_93q9mrZpWTlRMeta?.name ?? "issue-formid",
    path: issue_45form_91_91id_93_93q9mrZpWTlRMeta?.path ?? "/issue-form:id?",
    meta: issue_45form_91_91id_93_93q9mrZpWTlRMeta || {},
    alias: issue_45form_91_91id_93_93q9mrZpWTlRMeta?.alias || [],
    redirect: issue_45form_91_91id_93_93q9mrZpWTlRMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue-form[[id]].vue").then(m => m.default || m)
  },
  {
    name: details_45_91id_938zaJL4RdL0Meta?.name ?? "issue-details-id",
    path: details_45_91id_938zaJL4RdL0Meta?.path ?? "/issue/details-:id()",
    meta: details_45_91id_938zaJL4RdL0Meta || {},
    alias: details_45_91id_938zaJL4RdL0Meta?.alias || [],
    redirect: details_45_91id_938zaJL4RdL0Meta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue/details-[id].vue").then(m => m.default || m)
  },
  {
    name: issuesa2wSH6FP6vMeta?.name ?? "issues",
    path: issuesa2wSH6FP6vMeta?.path ?? "/issues",
    meta: issuesa2wSH6FP6vMeta || {},
    alias: issuesa2wSH6FP6vMeta?.alias || [],
    redirect: issuesa2wSH6FP6vMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issues.vue").then(m => m.default || m)
  }
]